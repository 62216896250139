@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: oswald;
    font-size: 18px;
}

Section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 25px 0;
    gap: 25px;
    min-height: 100lvh;

}

#Profil {
    width: 100%;
}

#Profil,
#Gallerie {
    background-color: rgb(25, 25, 25);
}

#Capa,
#Contact {
    background-color: rgb(50, 50, 50);
}

#Capa>h1 {
    margin: 5px;
}

#Capa>p {
    text-align: center;
    margin: 0 30%;
    border-radius: 25px 0;
    border: 5px solid black;
    background-color: rgb(255, 120, 0);
    padding: 25px;
    font-size: 20px;
}

#Contact{
    min-height: 0;
}

#portrait {
    position: relative;
    border-radius: 50px 0;
    width: 420px;
    border: 10px double;
    margin-bottom: 75px;
    filter: drop-shadow(0 0 0.75rem rgba(255, 255, 255, 0.75));
}

#salut {
    border-radius: 20px;
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: orange;
    filter: drop-shadow(0 0 0.75rem rgba(255, 166, 0, 0.2));
}

#capaTitle, #formTitle, #gallerieTitle {
    padding: 0;
    margin-bottom: 0;
    flex: 0 0 100%;
    text-align: center;
    font-size: xx-large;
    color: white;
}

@media (max-width: 1024px) {
    #Capa > p {
        margin: 0 10dvw;
    }

    #portrait { 
        width: 50dvw;
    }
}

@media (max-width: 768px) {
    #portrait { 
        width: 65dvw;
    }
}