#nav {
    width: 100%;
    position: sticky;
    top: 0;
    background-color: orange;
    margin-top: 0;
    padding: 5px 0;
    text-align: center;
    margin: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#nav > li{
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    list-style: none;
    scroll-behavior: smooth;
}