.capaGallerie{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 5px dotted;
    border-radius: 25px;
    width: 75vw;
    margin: 0;
    padding-bottom: 40px;
    min-height: 0;
    overflow: hidden;
}

.capaTitre{
    padding: 0;
    margin-bottom: 0;
    flex: 0 0 100%;
    text-align: center;
    font-size: xx-large;
    color: white;
}

.capaGallerie > div{
    margin: 20px 0;
    height: auto;
    width: 40%;
    padding: 25px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    border: 5px solid;
    filter: blur(5px);
}

.capaGallerie > div >h2{
    margin-bottom: 10px;
    margin-top: 0;
}

.frontendContainer{
    transform: translateX(-100%);
}

.backendContainer{
    transform: translateX(100%);
}

.capaGallerie > div.visible{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    transition: all 2s ease-in-out;
}

@media (max-width: 1024px) {
    .capaGallerie > div{
        width: 80%;
        margin: 0 10px;
    }
}