#gallerie_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-around;
    margin: 50px;
}

@media (max-width: 1024px) {
    #gallerie_container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 50px;
        justify-content: space-around;
        margin: 50px;
    }
}