.Footer {
    width: 100%;
    position: relative;
    bottom: 0;
    background-color: black;
    margin-bottom: 0;
    padding: 5px 0;
    text-align: center;
    color: orangered;
    margin: 0;
}