.containerCapa{
    position: relative;
    width: 100%;
    background: rgb(24, 26, 27);
    padding: 10px 20px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
}

.titreCapa{
    color: white;
}

.barreCapa{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0,0, 0, 0.2);
}

.percent{
    position: relative;
    display: block;
    height: 100%;
    border-radius: 6px;
}

.frontendContainer > .containerCapa > .barreCapa > .percent {
    background: orange;
}

.backendContainer > .containerCapa > .barreCapa > .percent {
    background: orangered;
}